import React, { useContext } from 'react';
import _ from 'lodash';

import { globalContext } from '../../../context/global';

import { PASSWORD_RESET } from '../../../constants/routes';

//Prime
import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';

import { Link } from 'react-router-dom';

export default function MainMenu() {
	const context = useContext(globalContext);
	const { roles } = context.store.user;

	const handleSelectBuild = () => {
		const options = _.map(roles, ({ codeRole, affiliateId, nameRole }) => {
			if (codeRole === 'AFFILIATE') {
				return {
					label: nameRole,
					command: () => context.setAffiliate(affiliateId)
				};
			}
		});
		return _.compact(options);
	};

	const getAffiliateName = () => {
		const affiliateName = _.reduce(
			roles,
			(acc, rol) => {
				if (rol.affiliateId === context.store.affiliateId) {
					acc = rol.nameRole;
				}
				return acc;
			},
			''
		);

		return affiliateName;
	};

	const items = [
		{
			label: getAffiliateName(),
			icon: 'pi pi-fw pi-user',
			items: handleSelectBuild()
		},
		{
			label: 'Traer formularios',
			icon: 'pi pi-fw pi-refresh',
			command: () => context.dataCollector()
		}
	];

	return (
		<Menubar model={items}>
			<Link to={PASSWORD_RESET} style={{ marginRight: 20 }}>
				Cambiar contraseña
			</Link>
			<Button label="Salir" icon="pi pi-power-off" onClick={() => context.logout()} />
		</Menubar>
	);
}
