import React, { useState, useContext } from 'react';
import { globalContext } from '../../context/global';

import { DASH } from '../../constants/routes';

import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';

export default function PasswordReset(props) {
	const context = useContext(globalContext);
	const [ oldPass, setOldPass ] = useState('');
	const [ newPass1, setNewPass1 ] = useState('');
	const [ newPass2, setNewPass2 ] = useState('');

	const handleResetPasword = async () => {
		await context.passwordReset(oldPass, newPass1, newPass2);
	};

	const HandleFlashMsg = () =>
		context.store.msg ? (
			<div className="msg">
				<Message severity={context.store.msgType} text={context.store.msg} />
			</div>
		) : null;

	if (context.store.loading) {
		return (
			<div className="page-loading">
				<ProgressSpinner />
			</div>
		);
	}

	const RedirectDashboard = () => {
		return props.history.push(DASH);
	};

	if (context.store.msgType === 'success') {
		return (
			<div className="page-login">
				<HandleFlashMsg />
				<Button label="Ir a selección de afiliado" onClick={RedirectDashboard} style={{ marginTop: 20 }} />
			</div>
		);
	}

	return (
		<div className="page-login">
			<HandleFlashMsg />
			<div className="container">
				<h1 className="first">Restablecer contraseña</h1>
				<div className="fields">
					<div className="p-inputgroup">
						<span className="p-inputgroup-addon">
							<i className="pi pi-key" />
						</span>
						<Password
							placeholder="Contraseña actual"
							value={oldPass}
							feedback={false}
							onChange={(e) => setOldPass(e.target.value)}
						/>
					</div>
					<div className="p-inputgroup">
						<span className="p-inputgroup-addon">
							<i className="pi pi-key" />
						</span>
						<Password
							placeholder="Nueva contraseña"
							value={newPass1}
							feedback={false}
							onChange={(e) => setNewPass1(e.target.value)}
						/>
					</div>
					<div className="p-inputgroup">
						<span className="p-inputgroup-addon">
							<i className="pi pi-key" />
						</span>
						<Password
							placeholder="Confirmar contraseña"
							value={newPass2}
							feedback={false}
							onChange={(e) => setNewPass2(e.target.value)}
						/>
					</div>
				</div>
				<Button label="Cambiar contraseña" onClick={handleResetPasword} />
			</div>
		</div>
	);
}
