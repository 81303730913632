import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";

//CSS
import "./table.css";
import "./dashboard.css";

//PrimeReact
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

//Comps
import MainMenu from "../MainMenu";
import AffiliateSelectorScreen from "../../AffiliateSelector/Screen";
import Lead from "../Lead";

//Context
import { globalContext } from "../../../context/global";

export default function MasterList() {
  const context = useContext(globalContext);

  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);

  const { affiliateId } = context.store;
  const ref = context.db
    .collection("lead")
    .where("affiliateId", "==", affiliateId)
    .where("availability.published", "==", true);

  useEffect(() => {
    const handleBlocker = (blocker) => {
      const { userId } = context.store.user;
      const isUserBlocker = userId === blocker.userId;
      return (
        <span className={isUserBlocker ? "blocker current-user" : "blocker"}>
          {blocker.date.toDate().toLocaleString()} por {blocker.nameUser}
        </span>
      );
    };
    const observer = ref.onSnapshot(async (snapshot) => {
      const tableFields = snapshot.docs.map((doc) => ({
        requestId: doc.id,
        fullName: doc.data().fullName,
        telefonoNumero: doc.data().telefonoNumero
          ? doc.data().telefonoNumero
          : "",
        tipoOperacion: doc.data().tipoOperacion ? doc.data().tipoOperacion : "", //Se habilita más adelante de lado server
        productoTipo: doc.data().productoTipo,
        nameCampaign: doc.data().nameCampaign,
        dateProcessCharge: doc.data().dateProcessCharge,
        scoreTotal: doc.data().scoreTotal,
        blocked: !doc.data().availability.blocked ? (
          <i className="pi pi-check" />
        ) : (
          <i className="pi pi-lock" />
        ),
        blocker:
          doc.data().availability.blocker &&
          handleBlocker(doc.data().availability.blocker),
        blockerId:
          doc.data().availability.blocker &&
          doc.data().availability.blocker.userId,
      }));
      const selectedRows = _.map(tableFields, (field) => {
        const { userId } = context.store.user;
        const isUserBlocker = userId === field.blockerId;
        return field.blocked.props.className === "pi pi-lock" && !isUserBlocker
          ? field
          : null;
      });

      setSelectedRow(selectedRows);
      setTableData(tableFields);
      setIsLoading(false);
    });
    setInterval(() => {
      context.renewSession();
      console.log("Session Renewed: Every 5mins...");
      //run every 5 min.
    }, 300000);
    return () => observer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliateId]);

  const { columns } = context.store;

  const dynamicColumns = columns.map(({ field, header }) => {
    if (field === "blocked") {
      return (
        <Column
          className={`col-${field}`}
          key={field}
          field={field}
          header={header}
        />
      );
    }
    return (
      <Column
        className={`col-${field}`}
        key={field}
        field={field}
        header={header}
        sortable={true}
        filter={true}
        filterMatchMode="contains"
      />
    );
  });

  if (!context.store.affiliateId) {
    return <AffiliateSelectorScreen />;
  }

  if (isLoading) {
    return <div>Cargando data...</div>;
  }

  return (
    <div className="page-dashboard">
      <MainMenu />
      <h1>Hola, {context.store.user.nameUser}</h1>
      <DataTable
        value={tableData}
        selection={selectedRow}
        responsive={true}
        reorderableColumns={true}
        selectionMode="single"
        paginator={true}
        rows={50}
        onSelectionChange={(e) => {
          context.setCurrentDoc(e.value.requestId);
        }}
      >
        {dynamicColumns}
      </DataTable>
      <div className="content-section implementation">
        <Lead />
      </div>
    </div>
  );
}
