import React from "react";
import _ from "lodash";

export default function Tipificacion({ tipFields }) {
  return (
    <ul>
      {_.map(tipFields, ({ fieldName, fieldLabel, value, type }) => {
        if (type === "show") {
          return (
            <li key={fieldName}>
              {fieldLabel}: {value}
            </li>
          );
        }
        return null;
      })}
    </ul>
  );
}
