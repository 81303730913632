import React, { useContext } from "react";
import _ from "lodash";

import { SelectButton } from "primereact/selectbutton";
import "./affiliate.css";

///Context
import { globalContext } from "../../context/global";

export default function AffiliateSelectorScreen() {
  const context = useContext(globalContext);
  const { roles } = context.store.user;
  const handleSelectBuild = () => {
    const options = _.map(roles, ({ codeRole, affiliateId, nameRole }) => {
      if (codeRole === "AFFILIATE") {
        return {
          label: nameRole,
          value: affiliateId
        };
      }
    });
    return (
      <SelectButton
        options={_.compact(options)}
        onChange={e => context.setAffiliate(e.value)}
      />
    );
  };
  if (roles.length === 1) {
    const { affiliateId } = roles[0];
    context.setAffiliate(affiliateId);
    return null;
  }
  return (
    <div className="page-affiliate-selector">
      <h1>Selecione un afiliado:</h1>
      <div>{handleSelectBuild()}</div>
    </div>
  );
}
