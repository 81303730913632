import React, { useContext } from "react";
import MasterList from "./MasterList";

import { LOGIN } from "../../constants/routes";

import { globalContext } from "../../context/global";

import { ProgressSpinner } from "primereact/progressspinner";

export default function Dashboard(props) {
  const context = useContext(globalContext);
  if (context.store.loading) {
    return (
      <div className="page-loading">
        <ProgressSpinner />
      </div>
    );
  }
  if (!context.store.user) {
    props.history.push(LOGIN);
    return null;
  }
  return (
    <React.Fragment>
      <MasterList />
    </React.Fragment>
  );
}
