export const ROOT = '/';

// Admin
export const DASH = '/dashboard';

//Public
export const LOGIN = '/login';

//Public
export const PASSWORD_RESET = '/passwordReset';
