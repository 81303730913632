import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";
import axios from "axios";
import { Formik } from "formik";

//config
import endpoint from "../../../config/endpoint";

//Helper
import formikInit from "./helpers/formikInit";

//Prime
import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";
import { Message } from "primereact/message";
import { TabView, TabPanel } from "primereact/tabview";

//comps
import Formulario from "./Formulario";
import Calificacion from "./Calificacion";
import Tipificacion from "./Tipificacion";
import Footer from "./Footer";

//CSS
import "./lead.css";

//Context
import { globalContext } from "../../../context/global";

export default function Lead() {
  const context = useContext(globalContext);
  const { currentDoc, calificacionDef } = context.store;

  const [isLoading, setIsLoading] = useState(true);
  const [flashMsg, setFlashMsg] = useState(null);
  const [err, setErr] = useState(false);

  const [initialValues, setInitialValues] = useState({});

  const token = localStorage.getItem("token");

  //formulario
  const [formFields, setFormFields] = useState([]);

  //tificacion
  const [tipFields, setTipFields] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(currentDoc)) {
      const reqData = async () => {
        setIsLoading(true);
        const token = localStorage.getItem("token");
        try {
          const reqDoc = await axios.post(`${endpoint}/getLeadInfo`, {
            leadId: currentDoc,
            token
          });
          if (reqDoc.data.statusOk) {
            const {
              fields,
              calificacionInitialValues,
              tipificacion
            } = reqDoc.data;
            setFormFields(fields);
            setTipFields(tipificacion);

            const initValues = formikInit.initialValues(
              fields,
              calificacionInitialValues
            );

            setInitialValues(initValues);
          }
          setIsLoading(false);
        } catch (error) {
          throw error;
        }
      };
      reqData();
    }
  }, [currentDoc]);

  const showMsg = () => {
    if (flashMsg === null) {
      return null;
    }
    if (err) {
      return <Message severity="error" text={flashMsg} />;
    }
    return <Message severity="success" text={flashMsg} />;
  };

  const handleSaveMsg = async status => {
    const handleFlashMsg = msg => {
      setFlashMsg(msg);
      setTimeout(() => setFlashMsg(null), 3000);
    };
    return status
      ? handleFlashMsg("Se guardó correctamente")
      : () => {
          setErr(true);
          handleFlashMsg("Hubo un error.");
        };
  };

  const handleExit = async formik => {
    setIsLoading(true);
    await context.unsetCurrentDoc(currentDoc, token);
    formik.resetForm();
    setIsLoading(false);
  };

  const shortCutData = () => {
    const {
      nombre,
      apellidoPrimer,
      apellidoSegundo,
      telefonoNumero
    } = initialValues;
    return (
      <div className="resume">
        <div>
          <div>
            <h3 style={{ margin: "unset" }}>
              {nombre} {apellidoPrimer} {apellidoSegundo}
            </h3>
          </div>
          <div>
            <strong>Tel.:</strong> {telefonoNumero}
          </div>
        </div>
        <div>
          <Tipificacion tipFields={tipFields} />
        </div>
      </div>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formikInit.validationSchema(
        formFields,
        calificacionDef
      )}
      enableReinitialize={true}
      onSubmit={async values => {
        setIsLoading(true);
        try {
          const reqUpdate = await axios.post(`${endpoint}/updateLeadInfo`, {
            leadId: currentDoc,
            values,
            token
          });
          handleSaveMsg(reqUpdate.data.statusOk);
          return setTimeout(
            () => context.unsetCurrentDoc(currentDoc, token),
            1200
          );
        } catch (error) {
          setErr(true);
        }
      }}
    >
      {formik => {
        return (
          <div className="lead-page">
            <Dialog
              header={currentDoc}
              visible={!_.isEmpty(currentDoc)}
              style={{ width: "75vw" }}
              footer={
                <Footer
                  isLoading={isLoading}
                  formik={formik}
                  calificacionDef={calificacionDef}
                />
              }
              onHide={() => handleExit(formik)}
            >
              <div style={{ margin: "15px auto" }}>{showMsg()}</div>
              <form onSubmit={formik.handleSubmit} id="lead-form">
                {isLoading ? (
                  <ProgressBar mode="indeterminate" />
                ) : (
                  <React.Fragment>
                    {shortCutData()}
                    <TabView>
                      <TabPanel header="Calificación">
                        <Calificacion formik={formik} />
                      </TabPanel>
                      <TabPanel header="Formulario">
                        <Formulario formFields={formFields} formik={formik} />
                      </TabPanel>
                    </TabView>
                  </React.Fragment>
                )}
              </form>
            </Dialog>
          </div>
        );
      }}
    </Formik>
  );
}
