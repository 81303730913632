import React, { useContext } from "react";

//Context
import { globalContext } from "../../../context/global";

//Helper
import FormikFieldsBuilder from "./helpers/FormikFieldsBuilder";

export default function Calificacion({ formik }) {
  const context = useContext(globalContext);
  const { calificacionDef } = context.store;
  return (
    <div>
      <div className="selects-container">
        {FormikFieldsBuilder(calificacionDef.combos, formik)}
      </div>
      <div className="tags-container">
        <div>
          <h4>Tags Cliente</h4>
          {FormikFieldsBuilder(calificacionDef.tagsCliente, formik)}
        </div>
        <div>
          <h4>Tags App</h4>
          {FormikFieldsBuilder(calificacionDef.tagsApp, formik)}
        </div>
        <div>
          <h4>Tags Web</h4>
          {FormikFieldsBuilder(calificacionDef.tagsWeb, formik)}
        </div>
      </div>
    </div>
  );
}
