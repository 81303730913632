import React, { Component } from "react";
import axios from "axios";

import "firebase/firestore";

import app from "firebase/app";
import firebaseConfig from "../config/firebase";
import endpoint from "../config/endpoint";

import { COLLECTION_SESSION, COLLECION_USER } from "../constants/collections";

export const globalContext = React.createContext();

class GlobalContext extends Component {
  constructor(props) {
    super(props);
    app.initializeApp(firebaseConfig);
    this.db = app.firestore();
    this.state = {
      loading: true,
      user: null,
      affiliateId: null,
      columns: [],
      calificacionDef: {},
      currentDoc: null,
      msg: null,
      msgType: null
    };
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.passwordReset = this.passwordReset.bind(this);
    this.setAffiliate = this.setAffiliate.bind(this);
    this.dataCollector = this.dataCollector.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.setCurrentDoc = this.setCurrentDoc.bind(this);
    this.unsetCurrentDoc = this.unsetCurrentDoc.bind(this);
  }
  componentDidMount() {
    this.setGridSchema();
    const currentSessionLocal = localStorage.getItem("sessionId");
    if (!currentSessionLocal) {
      this.setState({ loading: false });
      return null;
    }
    return this.setUser(currentSessionLocal);
  }
  async setGridSchema() {
    try {
      const getSchema = await axios.get(`${endpoint}/gridSchema`);
      return this.setState({
        columns: getSchema.data.gridSchema,
        calificacionDef: getSchema.data.calificacionDef
      });
    } catch (error) {
      throw error;
    }
  }
  async setAffiliate(affiliateId) {
    await this.setState({ loading: true });
    const sessionRef = this.db.collection(COLLECTION_SESSION);
    const { sessionId } = this.state.user;
    await sessionRef.doc(sessionId).update({ affiliateId });
    await this.renewSession();
    return this.setState({
      affiliateId,
      loading: false
    });
  }
  async setUser(sessionId) {
    try {
      const userRef = this.db.collection(COLLECION_USER);
      const sessionRef = this.db.collection(COLLECTION_SESSION);
      const session = await sessionRef.doc(sessionId).get();
      if (!session.exists) {
        localStorage.clear();
        return this.setState({ loading: false });
      }
      const { userId } = session.data();
      const user = await userRef.doc(userId).get();
      if (user.exists) {
        return this.setState({
          ...this.state,
          loading: false,
          user: {
            ...user.data(),
            userId
          }
        });
      }
    } catch (error) {
      throw error;
    }
  }
  flashMsg(msg) {
    this.setState({ msg });
    setTimeout(() => this.setState({ msg: null }), 3000);
  }
  logout() {
    localStorage.clear();
    return this.setState({
      user: null,
      affiliateId: null
    });
  }
  async login(username, password) {
    this.setState({ loading: true });
    try {
      const response = await axios.post(`${endpoint}/login`, {
        username,
        password
      });
      const user = response.data;
      if (user.login) {
        localStorage.setItem("sessionId", user.sessionId);
        localStorage.setItem("token", user.token);
        localStorage.setItem("userId", user.userId);
        this.setState({
          ...this.state,
          user
        });
        this.setState({ loading: false });
      }
      return this.setState({
        loading: false,
        msg: response.data.message,
        msgType: "error"
      });
    } catch (error) {
      return this.setState({
        loading: false,
        msg: error,
        msgType: "error"
      });
    }
  }
  async passwordReset(oldPass, newPass1, newPass2) {
    this.setState({ loading: true });
    try {
      const passwordReset = await axios.post(`${endpoint}/passwordReset`, {
        userId: localStorage.getItem("userId"),
        oldPass,
        newPass1,
        newPass2
      });
      const response = passwordReset.data;
      if (response.statusOk) {
        this.setState({
          loading: false,
          msg: response.message,
          msgType: "success"
        });
      } else {
        this.setState({
          loading: false,
          msg: response.message,
          msgType: "error"
        });
      }
      return response;
    } catch (error) {
      return this.setState({
        loading: false,
        msg: error,
        msgType: "error"
      });
    }
  }
  async renewSession() {
    try {
      return await axios.post(`${endpoint}/renewSession`, {
        sessionId: this.state.user.sessionId
      });
    } catch (error) {
      throw error;
    }
  }
  setCurrentDoc(id) {
    this.freezeDoc(id);
    return this.setState({
      currentDoc: id
    });
  }
  async unsetCurrentDoc(id, token) {
    if (id === null) {
      return null;
    }
    await axios.post(`${endpoint}/unlockLead`, {
      leadId: id,
      token
    });
    this.unFreezeDoc(id);
    return this.setState({
      currentDoc: null
    });
  }
  async freezeDoc(id) {
    try {
      const leadRef = await this.db.collection("lead").doc(id);
      const blocker = {
        userId: this.state.user.userId,
        nameUser: this.state.user.nameUser,
        date: new Date()
      };
      const leadData = await leadRef.get();
      return await leadRef.update({
        availability: {
          ...leadData.data().availability,
          blocked: true,
          blocker
        }
      });
    } catch (error) {
      throw error;
    }
  }
  async unFreezeDoc(id) {
    try {
      const leadRef = await this.db.collection("lead").doc(id);
      return await leadRef.get();
      //const leadData = await leadRef.get();
      //return await leadRef.update({ availability: { ...leadData.data().availability } });
    } catch (error) {
      throw error;
    }
  }
  async dataCollector() {
    const { affiliateId } = this.state;
    this.setState({ loading: true });
    const token = localStorage.getItem("token");
    try {
      await axios.post(`${endpoint}/dataCollector`, { affiliateId, token });
      return this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      throw error;
    }
  }

  render() {
    return (
      <globalContext.Provider
        value={{
          store: this.state,
          login: this.login,
          logout: this.logout,
          passwordReset: this.passwordReset,
          setAffiliate: this.setAffiliate,
          renewSession: this.renewSession,
          db: this.db,
          setCurrentDoc: this.setCurrentDoc,
          unsetCurrentDoc: this.unsetCurrentDoc,
          dataCollector: this.dataCollector
        }}
      >
        {this.props.children}
      </globalContext.Provider>
    );
  }
}

export default GlobalContext;
