import * as Yup from "yup";
import _ from "lodash";

function initialValues(formFields, calificacionInitialValues) {
  return _.reduce(
    formFields,
    (acc, field) => {
      return {
        ...acc,
        [field.fieldName]: field.value
      };
    },
    calificacionInitialValues
  );
}

function validationSchema(formFields, calificacionDef) {
  const formValFields = _.reduce(
    formFields,
    (acc, field) => {
      if (field.type !== "hidden" && field.required) {
        return {
          ...acc,
          [field.fieldName]: Yup.string().required("Campo requerido.")
        };
      }
      return acc;
    },
    {}
  );

  const allCalificacionFields = _.reduce(
    calificacionDef,
    (acc, field) => {
      acc.push(...field);
      return acc;
    },
    []
  );

  const calificacionValFields = _.reduce(
    allCalificacionFields,
    (acc, field) => {
      if (field.required === "true") {
        return {
          ...acc,
          [field.fieldName]: Yup.string().required("Campo requerido.")
        };
      }
      return acc;
    },
    {}
  );
  return Yup.object({
    ...formValFields,
    ...calificacionValFields
  });
}

export default {
  initialValues,
  validationSchema
};
