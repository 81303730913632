import React from "react";
import _ from "lodash";

//Primereact
import { Button } from "primereact/button";
import { Message } from "primereact/message";

export default function Footer({ isLoading, formik, calificacionDef }) {
  if (isLoading) {
    return (
      <div>
        <Button
          label="Cargando..."
          icon="pi pi pi-spin pi-spinner"
          disabled="disabled"
        />
      </div>
    );
  }

  if (_.includes(formik.errors, "Valor negativo.")) {
    return (
      <div>
        <Message severity="warn" text="Hay algunos campos negativos" />
        &nbsp;
        <Button
          label="Guardar de igual forma"
          onClick={() => {
            formik.setErrors({});
            formik.setFieldValue("keepListed", true);
            formik.submitForm();
          }}
          className="p-button-warning"
          icon="pi pi-paperclip"
          type="submit"
          value="Submit"
          form="lead-form"
        />
      </div>
    );
  }

  if (!_.isEmpty(formik.errors)) {
    return (
      <div>
        <Message severity="warn" text="Hay algunos campos sin validar." />
      </div>
    );
  }

  const handleConditionalSave = () => {
    const { calificacion, respuestaApp, respuestaWeb } = formik.values;

    const negativeValues = _.reduce(
      calificacionDef.combos,
      (acc, combo) => {
        const combosArr = _.reduce(
          combo.options,
          (acc, c) => {
            if (c.comboValue === "NEGATIVO") {
              return [...acc, c.fieldValue];
            }
            return acc;
          },
          []
        );
        return [...acc, ...combosArr];
      },
      []
    );

    if (_.includes(negativeValues, calificacion)) {
      return formik.setFieldError("calificacion", "Valor negativo.");
    }
    if (_.includes(negativeValues, respuestaApp)) {
      return formik.setFieldError("respuestaApp", "Valor negativo.");
    }
    if (_.includes(negativeValues, respuestaWeb)) {
      return formik.setFieldError("respuestaWeb", "Valor negativo.");
    }
    return formik.setFieldValue("keepListed", true);
  };

  return (
    <div>
      <Button
        label="Guardar y mantener en lista"
        icon="pi pi-paperclip"
        onClick={() => handleConditionalSave()}
        type="submit"
        value="Submit"
        form="lead-form"
      />
      <Button
        label="Guardar"
        className="p-button-success"
        icon="pi pi-check"
        type="submit"
        value="Submit"
        form="lead-form"
      />
    </div>
  );
}
