import React from "react";
import _ from "lodash";
import { Message } from "primereact/message";

export default function FieldError({ err }) {
  const isErr = !_.isEmpty(err);
  if (isErr) {
    return (
      <Message
        severity="error"
        text={err}
        style={{ fontSize: "x-small", margin: "5px 0" }}
      ></Message>
    );
  }
  return null;
}
