import React, { useState, useContext } from 'react';
import { globalContext } from '../../context/global';

import { DASH } from '../../constants/routes';

import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';

export default function Login(props) {
	const context = useContext(globalContext);
	const [ username, setUsername ] = useState('');
	const [ password, setPassword ] = useState('');

	const handleLogin = async () => {
		await context.login(username, password);
		if (!context.store.user) {
			return props.history.push(DASH);
		}
	};

	const HandleFlashMsg = () =>
		context.store.msg ? (
			<div className="msg">
				<Message severity="error" text={context.store.msg} />
			</div>
		) : null;

	if (context.store.user) {
		props.history.push(DASH);
	}
	if (context.store.loading) {
		return (
			<div className="page-loading">
				<ProgressSpinner />
			</div>
		);
	}
	return (
		<div className="page-login">
			<HandleFlashMsg />
			<div className="container">
				<h1 className="first">Ingreso de usuario</h1>
				<div className="fields">
					<div className="p-inputgroup">
						<span className="p-inputgroup-addon">
							<i className="pi pi-user" />
						</span>
						<InputText placeholder="Usuario" value={username} onChange={(e) => setUsername(e.target.value)} />
					</div>
					<div className="p-inputgroup">
						<span className="p-inputgroup-addon">
							<i className="pi pi-key" />
						</span>
						<Password
							placeholder="Contraseña"
							value={password}
							feedback={false}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>
				</div>
				<Button label="Ingresar" onClick={handleLogin} />
			</div>
		</div>
	);
}
