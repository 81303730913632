import React from "react";

//Helper
import FormikFieldsBuilder from "./helpers/FormikFieldsBuilder";

export default function Formulario({ formFields, formik }) {
  return (
    <div className="lead-field-list">
      {FormikFieldsBuilder(formFields, formik)}
    </div>
  );
}
