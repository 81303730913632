import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

import Dashboard from '../Dashboard';

//auth
import { Login } from '../Auth';
import { PasswordReset } from '../Auth';

//css
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './global.css';

//context
import GlobalContext from '../../context/global';

function App() {
	return (
		<GlobalContext>
			<Router>
				<div className="App">
					<Route path={ROUTES.LOGIN} exact component={Login} />
					<Route path={ROUTES.PASSWORD_RESET} exact component={PasswordReset} />
					<Route path={ROUTES.DASH} exact component={Dashboard} />
					<Route path={ROUTES.ROOT} exact component={Dashboard} />
				</div>
			</Router>
		</GlobalContext>
	);
}

export default App;
