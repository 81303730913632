import React from 'react';
import _ from 'lodash';

import '../custom-select.css';
import FieldError from './FieldError';

// PrimeReact
import { ToggleButton } from 'primereact/togglebutton';
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';

export default function FormikFieldsBuilder(fields, formik) {
	const { values, handleChange, errors } = formik;

	return _.map(fields, ({ fieldName, fieldLabel, type, options }) => {
		if (type === 'hidden') {
			return null;
		}
		switch (type) {
			case 'select':
				const selectItems = _.map(options, (option) => {
					return {
						label: option.fieldText,
						value: option.fieldValue
					};
				});
				selectItems.splice(0, 0, {
					label: 'Seleccione una opción',
					value: ''
				});
				return (
					<div key={fieldName} className="lead-field">
						<label>{fieldLabel}</label>
						<Dropdown
							value={values[fieldName]}
							options={selectItems}
							name={fieldName}
							placeholder={values[fieldName]}
							onChange={handleChange}
						/>
						<FieldError err={errors[fieldName] || ''} />
					</div>
				);
			case 'selectGrouped':
				const optionsGrouped = _.groupBy(options, 'group');
				const handleOptionsGrouped = _.map(optionsGrouped, (option, key) => {
					return (
						<optgroup label={key}>
							{_.map(option, ({ fieldText, fieldValue }) => (
								<option value={fieldValue} comboValue="asd">
									{fieldText}
								</option>
							))}
						</optgroup>
					);
				});
				handleOptionsGrouped.splice(
					0,
					0,
					<option value="" defaultValue key="default">
						Seleccione una opción
					</option>
				);
				return (
					<div key={fieldName} className="lead-field" style={{ marginBottom: 15 }}>
						<label>{fieldLabel}</label>
						<select onChange={handleChange} name={fieldName} className="custom-select" value={values[fieldName]}>
							{handleOptionsGrouped}
						</select>
						<FieldError err={errors[fieldName] || ''} />
					</div>
				);
			case 'selectButton':
				const selectItems1 = _.map(options, (option) => {
					return {
						label: option.fieldText,
						value: option.fieldValue
					};
				});
				return (
					<div key={fieldName} className="lead-field" style={{ marginBottom: 15 }}>
						<label>{fieldLabel}</label>
						<SelectButton options={selectItems1} name={fieldName} onChange={handleChange} value={values[fieldName]} />
						<FieldError err={errors[fieldName] || ''} />
					</div>
				);
			case 'switch':
				return (
					<div key={fieldName} className="lead-field">
						<label>{fieldLabel}</label>
						<InputSwitch checked={values[fieldName]} name={fieldName} onChange={handleChange} />
						<FieldError err={errors[fieldName] || ''} />
					</div>
				);
			case 'boolean':
				return (
					<div key={fieldName} className="lead-field">
						<label>{fieldLabel}</label>
						<ToggleButton checked={values[fieldName]} name={fieldName} onChange={handleChange} />
						<FieldError err={errors[fieldName] || ''} />
					</div>
				);
			default:
				return (
					<div key={fieldName} className="lead-field">
						<label htmlFor={fieldName}>{fieldLabel}</label>
						<InputText id={fieldName} value={values[fieldName]} name={fieldName} onChange={handleChange} />
						<FieldError err={errors[fieldName] || ''} />
					</div>
				);
		}
	});
}
